import React, { useState } from 'react';
import { styled } from '@mui/system';
import { Grid, TextField, Autocomplete, Box, Checkbox, ListItemText } from '@mui/material';
import { MenuItem } from '@mui/material';
import { ToggleButton, ToggleButtonGroup, IconButton } from '@mui/material';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import Tooltip from '@mui/material/Tooltip';

const FormContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '50%', // Adjust the width as needed
    margin: 'auto',
});

const Form = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
});

const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

const PerfBase = (
    { electricPrice,
        setElectricPrice,
        buildingType,
        setBuildingType,
        manfbuildingType,
        setManfbuildingType,
        buildingloadperc,
        setBuildingloadperc,
        AnnualPerfDgrade,
        setAnnualPerfDgrade,
        designedCapacity,
        setDesignedCapacity,
        designedCOP,
        setDesignedCOP,
        currency,
        country,
        setCurrency,
        investment,
        setInvestment,
        fullChillerCode,
        setFullChillerCode,
        eleccosterror,
        investerror,
        chillercaperror,
        setEleccosterror,
        setInvesterror,
        setChillercaperror,
        modelNumber,
        setModelNumber,
        chillerSerialNum,
        setChillerSerialNum,
        siteName,
        setSiteName,
        customerName,
        setCustomerName,
        evapChecked,
        manf_source,
        setmanf_source,
        power_supply,
        setPower_supply,
        ManfData,
        evap_tdb_min_data,
        evap_rh_max_data,
        setEvap_tdb_min_data,
        setEvap_rh_max_data,
        evap_cond_pump_power,
        evap_cond_fan_power,
        evap_cond_operating_months,
        setEvap_cond_pump_power,
        setEvap_cond_fan_power,
        setEvap_cond_operating_months
    }
) => {
    const [alignment, setAlignment] = useState([]);
    const [manfcProfile, setManfcProfile] = useState([]);
    const [evadCondProfile, setevadCondProfile] = useState([]);


    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const handleChangeManf = (event) => {
        setmanf_source(event.target.value);
        setPower_supply(ManfData[event.target.value]);
    };

    const handleChangePowerSupply = (event) => {
        setPower_supply(event.target.value);
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        switch (id) {
            case 'electricPrice':
                setElectricPrice(value);
                setEleccosterror(false)
                break;
            case 'buildingType':
                setBuildingType(value);
                break;
            case 'manfbuildingType':
                setManfbuildingType(value);
                break;
            case 'fullChillerCode':
                setFullChillerCode(value);
                break;
            case 'AnnualPerfDgrade':
                if (!isNaN(value) && value <= 1.00) {
                    setAnnualPerfDgrade(value);
                }
                else {

                }
                break;
            case 'buildingloadperc':
                if (!isNaN(value) && value <= 100.0) {
                    setBuildingloadperc(value);
                }
                else {

                }
                break;
            case 'designedCOP':
                setDesignedCOP(value);
                break;
            case 'modelNumber':
                setModelNumber(value);
                break;
            case 'chillerSerialNum':
                setChillerSerialNum(value);
                break;
            case 'siteName':
                setSiteName(value);
                break;
            case 'customerName':
                setCustomerName(value);
                break;
            case 'designedCapacity':
                setDesignedCapacity(value);
                setChillercaperror(false);
                break;
            case 'investment':
                setInvestment(value);
                setInvesterror(false)
                break;
            case 'evap_tdb_min_data':
                setEvap_tdb_min_data(value);
                break;
            case 'evap_rh_max_data':
                setEvap_rh_max_data(value);
                break;
            case 'evap_cond_pump_power':
                setEvap_cond_pump_power(value)
                break;
            case 'evap_cond_fan_power':
                setEvap_cond_fan_power(value)
                break;
            default:
                break;
        }

    };

    const SmallLabel = styled('span')({
        fontSize: '0.85rem', // Adjust the font size as needed
        fontWeight: 'bold', // You can adjust other styles here as well
    });


    const handleTypeChange = (event, newAlignment) => {
        if (alignment === newAlignment) {
            setAlignment(null); // Toggle off if the same button is clicked again
        } else {
            setAlignment(newAlignment);

        }
        console.log(alignment)
    };

    const handleManfTypeChange = (event, newManfcProfile) => {
        if (manfcProfile === newManfcProfile) {
            setManfcProfile(null); // Toggle off if the same button is clicked again
        } else {
            setManfcProfile(newManfcProfile);
        }
        console.log(manfcProfile)
    };

    const handleevapCondChange = (event, newevapCondProfile) => {
        if (evadCondProfile === newevapCondProfile) {
            setevadCondProfile(null); // Toggle off if the same button is clicked again
        } else {
            setevadCondProfile(newevapCondProfile);
        }
    };

    const handleBuildingTypeChange = (e) => {
        setBuildingType(e.target.value);
        setManfbuildingType("Commercial (Office / Shopping Mall)");

    };

    const handleManfBuildingTypeChange = (e) => {
        setManfbuildingType(e.target.value);
    };

    const handleCurrencyChange = (event) => {
        setCurrency(event.target.value);
    };

    const buildingTypeOptions = [
        'Commercial (Office / Shopping Mall)',
        'Retail',
        'Residential',
        'Manufacturing',
        'Hotels',
        'Hospitals',
        'Schools / Universities',
    ];

    const ManfacturingTypeOptions = [
        'Commercial (Office / Shopping Mall)',
        'Retail',
        'Residential',
        'Hotels',
        'Hospitals',
        'Schools / Universities',
    ];

    const handleClear = () => {
        setElectricPrice('');
        setBuildingType('');
        setManfbuildingType('');
        setAnnualPerfDgrade('1.00');
        setDesignedCapacity('');
        setDesignedCOP('');
        setFullChillerCode('');
        setInvestment('');
    };

    const handleMonthChange = (event, selectedOptions) => {
        setEvap_cond_operating_months(selectedOptions);
        // console.log("selectedOptions", selectedOptions)
    };

    return (
        <div>
            {/* <Button style={{ padding: 0, float: "right" }} onClick={handleClear}>Clear</Button> */}
            <FormContainer style={{ paddingTop: '0rem', width: "80%" }}>
                {/* <Typography variant="h4">Performance Base</Typography> */}
                <Form onSubmit={handleSubmit}>

                    <Grid container spacing={2}>
                        <Grid style={{}} item xs={3} sm={6}>
                            {/* <Tooltip
                                title={
                                    buildingType === "Residential" ? (
                                        <React.Fragment>
                                            <div>• Using Hospital Building data as reference</div>
                                        </React.Fragment>
                                    ) : ""
                                }
                                placement="bottom-end"
                                arrow
                            >
                            </Tooltip> */}
                            <TextField
                                id="buildingType"
                                label={<SmallLabel>Building Type</SmallLabel>}
                                variant="outlined"
                                fullWidth
                                select
                                value={buildingType}
                                onChange={handleBuildingTypeChange}
                            >
                                {buildingTypeOptions.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        <SmallLabel>{option}</SmallLabel>
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={16} sm={6}>
                            <TextField
                                id="designedCapacity"
                                label={<SmallLabel>Designed Chiller Capacity (kW)</SmallLabel>}
                                variant="outlined"
                                fullWidth

                                type="number"
                                inputProps={{ min: 0 }}
                                value={designedCapacity}
                                onChange={handleChange}
                                error={chillercaperror}
                                helperText={chillercaperror ? 'Please enter the Designed Chiller Capacity.' : ''}
                            />
                        </Grid>

                        <Grid item xs={16} sm={6}>
                            <TextField
                                id="electricPrice"
                                label={<SmallLabel>Electrical cost per kWh</SmallLabel>}
                                variant="outlined"
                                fullWidth

                                type="number"
                                inputProps={{ min: 0 }}
                                value={electricPrice}
                                onChange={handleChange}
                                error={eleccosterror}
                                helperText={eleccosterror ? 'Please enter the Electrical cost.' : ''}
                                required
                            />
                        </Grid>

                        <Grid item xs={16} sm={4}>

                            <TextField
                                id="investment"
                                label={<SmallLabel>Investment</SmallLabel>}
                                variant="outlined"
                                fullWidth

                                type="number"
                                inputProps={{ min: 0 }}
                                value={investment}
                                onChange={handleChange}
                                error={investerror}
                                helperText={investerror ? 'Please enter Investment.' : ''}
                            />

                        </Grid>
                        <Grid style={{ paddingLeft: 0 }} item xs={16} sm={2}>
                            <TextField
                                id="currency"
                                label={<SmallLabel>Currency</SmallLabel>}
                                variant="outlined"
                                fullWidth

                                select
                                type="number"
                                inputProps={{ min: 0 }}
                                value={currency}
                                onChange={handleCurrencyChange}
                                required
                            >
                                <MenuItem value={"KWD"}><SmallLabel>KWD</SmallLabel></MenuItem>
                                <MenuItem value={"AED"}><SmallLabel>AED</SmallLabel></MenuItem>
                                <MenuItem value={"QAR"}><SmallLabel>QAR</SmallLabel></MenuItem>
                                <MenuItem value={"SAR"}><SmallLabel>SAR</SmallLabel></MenuItem>
                                <MenuItem value={"GBP"}><SmallLabel>GBP</SmallLabel></MenuItem>
                                <MenuItem value={"EUR"}><SmallLabel>EUR</SmallLabel></MenuItem>
                            </TextField>

                        </Grid>

                        <Grid item xs={3} sm={6}>
                            {!evapChecked && <Tooltip
                                title={
                                    <React.Fragment>
                                        <div>• 0.25% per year - Good maintenance</div>
                                        <div>• 0.50% per year - Average maintenance</div>
                                        <div>• 0.75% per year - Below average maintenance</div>
                                        <div>• 1.00% per year - Bad maintenance</div>
                                    </React.Fragment>
                                }
                                placement="bottom-end"
                                arrow
                            >
                                <TextField
                                    id="AnnualPerfDgrade"
                                    label={<SmallLabel>Annual Performance Downgrading Factor Percentage (Max Downgrading)</SmallLabel>}
                                    variant="outlined"
                                    fullWidth

                                    type="number"
                                    inputProps={{ min: 0 }}
                                    value={AnnualPerfDgrade}
                                    onChange={handleChange} // Use handleInputChange for input validation
                                    InputProps={{
                                        endAdornment: '%', // Add percentage symbol as the input's end adornment
                                    }}
                                />
                            </Tooltip>}
                        </Grid>



                    </Grid>

                    <Grid container spacing={1} width={"50%"}>
                        <Grid item xs={10} sm={3}>
                            <ToggleButtonGroup
                                color="primary"
                                value={alignment}
                                exclusive={false}
                                size='small'
                                onChange={handleTypeChange}
                                aria-label="Platform"
                            >
                                <ToggleButton style={{ color: "#1976d2", padding: 0, paddingLeft: "10px" }} value="Advanced">{
                                    <SmallLabel>Advanced
                                        <IconButton
                                            edge="end"

                                        >
                                            {alignment.length > 0 ? (
                                                <KeyboardDoubleArrowUpIcon color="primary" />

                                            ) : (
                                                <KeyboardDoubleArrowDownIcon color="primary" />
                                            )}
                                        </IconButton> </SmallLabel>}</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        {buildingType === "Manufacturing" &&
                            <Grid item xs={10} sm={5}>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={manfcProfile}
                                    exclusive={false}
                                    size='small'
                                    onChange={handleManfTypeChange}
                                    aria-label="Platform"
                                >

                                    <ToggleButton style={{ color: "#1976d2", padding: 0, paddingLeft: "10px" }} value="manf_profile">{
                                        <SmallLabel>Manufacturing Profile
                                            <IconButton
                                                edge="end"

                                            >
                                                {manfcProfile.length > 0 ? (
                                                    <KeyboardDoubleArrowUpIcon color="primary" />

                                                ) : (
                                                    <KeyboardDoubleArrowDownIcon color="primary" />
                                                )}
                                            </IconButton> </SmallLabel>}</ToggleButton>

                                </ToggleButtonGroup>
                            </Grid>
                        }
                        {evapChecked &&
                            <Grid item xs={10} sm={4}>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={evadCondProfile}
                                    exclusive={false}
                                    size='small'
                                    onChange={handleevapCondChange}
                                    aria-label="Platform"
                                >

                                    <ToggleButton style={{ color: "#1976d2", padding: 0, paddingLeft: "10px" }} value="evap cond">{
                                        <SmallLabel>Evap. Cond. Params
                                            <IconButton
                                                edge="end"
                                            >
                                                {evadCondProfile.length > 0 ? (
                                                    <KeyboardDoubleArrowUpIcon color="primary" />

                                                ) : (
                                                    <KeyboardDoubleArrowDownIcon color="primary" />
                                                )}
                                            </IconButton> </SmallLabel>}</ToggleButton>

                                </ToggleButtonGroup>
                            </Grid>
                        }
                    </Grid>





                    {alignment.length > 0 ? (
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={16} sm={6}>
                                    <TextField
                                        id="modelNumber"
                                        label={<SmallLabel>Model Number</SmallLabel>}
                                        variant="outlined"
                                        fullWidth
                                        value={modelNumber}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={16} sm={6}>
                                    <TextField
                                        id="chillerSerialNum"
                                        label={<SmallLabel>Chiller Serial Number</SmallLabel>}
                                        variant="outlined"
                                        fullWidth
                                        value={chillerSerialNum}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={16} sm={6}>
                                    <TextField
                                        id="siteName"
                                        label={<SmallLabel>Site Name</SmallLabel>}
                                        variant="outlined"
                                        fullWidth
                                        value={siteName}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={16} sm={6}>
                                    <TextField
                                        id="customerName"
                                        label={<SmallLabel>Customer Name</SmallLabel>}
                                        variant="outlined"
                                        fullWidth
                                        value={customerName}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={16} sm={6}>
                                    <div>
                                        <Autocomplete
                                            sx={{
                                                border: '1.5px solid #ccc',  // Add a border
                                                borderRadius: '2px',       // Optional: Add border radius for rounded corners
                                            }}
                                            multiple
                                            size='small'
                                            limitTags={7}
                                            id="combo-box-demo"
                                            options={[...months]}  // Add 'Select All' option
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option}
                                            value={evap_cond_operating_months}
                                            onChange={handleMonthChange}
                                            isOptionEqualToValue={(option, value) => option === value || value === ""}
                                            renderInput={(params) => (
                                                <TextField
                                                    style={{ padding: "2px" }}
                                                    {...params}
                                                    variant="standard"
                                                    label={<SmallLabel style={{ paddingLeft: "1rem" }}>Operating Months</SmallLabel>}
                                                    placeholder="Months"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        disableUnderline: true
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={16} sm={3}>
                                    <TextField
                                        id="manf_source"
                                        label={<SmallLabel>Manufacturing</SmallLabel>}
                                        variant="outlined"
                                        fullWidth

                                        select
                                        value={manf_source}
                                        onChange={handleChangeManf}
                                    >
                                        {Object.keys(ManfData).map((manf) => (
                                            <MenuItem key={manf} value={manf}>
                                                <SmallLabel>{manf}</SmallLabel>
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={16} sm={3}>
                                    <TextField
                                        id="powersupply"
                                        label={<SmallLabel>Power Supply</SmallLabel>}
                                        variant="outlined"
                                        fullWidth

                                        select
                                        value={power_supply}
                                        onChange={handleChangePowerSupply}
                                    >
                                        {["50Hz", "60Hz"].map((unit) => (
                                            <MenuItem key={unit} value={unit}>
                                                <SmallLabel>{unit}</SmallLabel>
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                {/* <Grid item xs={16} sm={6}>
                                    <TextField
                                        id="designedCOP"
                                        label={<SmallLabel>Designed COP</SmallLabel>}
                                        variant="outlined"
                                        fullWidth

                                        type="number"
                                        inputProps={{ min: 0 }}
                                        value={designedCOP}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={16} sm={6}>
                                    <TextField
                                        id="fullChillerCode"
                                        label={<SmallLabel>Full Chiller Code</SmallLabel>}
                                        variant="outlined"
                                        fullWidth

                                        value={fullChillerCode}
                                        onChange={handleChange}
                                    />
                                </Grid> */}


                            </Grid>

                        </>
                    ) :
                        <div></div>
                    }

                    {buildingType === "Manufacturing" && manfcProfile.length > 0 ? (
                        <>
                            <Grid container spacing={2} width={'70%'}>
                                <Grid item xs={16} sm={6}>
                                    <TextField
                                        id="manfbuildingType"
                                        label={<SmallLabel>Building Type</SmallLabel>}
                                        variant="outlined"
                                        fullWidth

                                        select
                                        value={manfbuildingType}
                                        onChange={handleManfBuildingTypeChange}
                                    >
                                        {ManfacturingTypeOptions.map((option) => (
                                            <MenuItem key={option} value={option}><SmallLabel>{option}</SmallLabel></MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={16} sm={3}>
                                    <TextField
                                        id="buildingloadperc"
                                        label={<SmallLabel>Manufacturing Load</SmallLabel>}
                                        variant="outlined"
                                        fullWidth

                                        type="number"
                                        inputProps={{ min: 0 }}
                                        value={buildingloadperc}
                                        onChange={handleChange} // Use handleInputChange for input validation
                                        InputProps={{
                                            endAdornment: '%', // Add percentage symbol as the input's end adornment
                                        }}
                                    />
                                </Grid>

                            </Grid>

                        </>
                    ) :
                        <div></div>
                    }

                    {evapChecked && evadCondProfile.length > 0 ? (
                        <>
                            <Grid container spacing={2} width={'100%'}>
                                <Grid item xs={16} sm={2}>
                                    <TextField
                                        id="evap_tdb_min_data"
                                        label={<SmallLabel>Min OAT for Evap Cond Operation (°C)</SmallLabel>}
                                        variant="outlined"
                                        fullWidth

                                        type="number"
                                        value={evap_tdb_min_data}
                                        onChange={handleChange}

                                    />
                                </Grid>
                                <Grid item xs={16} sm={2}>
                                    <TextField
                                        id="evap_rh_max_data"
                                        label={<SmallLabel>Max RH for Evap Cond Operation (%)</SmallLabel>}
                                        variant="outlined"
                                        fullWidth

                                        type="number"
                                        value={evap_rh_max_data}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                            {/* Second Row */}
                            <Grid container spacing={2} width={'100%'}>
                                <Grid item xs={16} sm={2}>
                                    <TextField
                                        id="evap_cond_pump_power"
                                        label={<SmallLabel>Additional Pump Power (W)</SmallLabel>}
                                        variant="outlined"
                                        fullWidth

                                        type="number"
                                        value={evap_cond_pump_power}
                                        onChange={handleChange}

                                    />
                                </Grid>

                                <Grid item xs={16} sm={2}>
                                    <TextField
                                        id="evap_cond_fan_power"
                                        label={<SmallLabel>Additional Fan Power (W)</SmallLabel>}
                                        variant="outlined"
                                        fullWidth

                                        type="number"
                                        value={evap_cond_fan_power}
                                        onChange={handleChange}
                                    />
                                </Grid>


                            </Grid>
                        </>
                    ) :
                        <div>

                        </div>
                    }

                </Form >
            </FormContainer >
        </div>
    );
};

export default PerfBase;
