import React from "react";
import {logout} from '@carrier-sce-sharedpackages/auth-module';
import appConfig from "../config";

const Exit = () => {
  localStorage.clear();
  logout(appConfig.api);
};

export default Exit;
